import { Link } from "react-router-dom";

/* ICONS */
import { FaBookMedical } from "react-icons/fa6";
import { IoHeadsetOutline } from "react-icons/io5";

const Sidebar = () => {
  var pathname = window.location.pathname;

  return (
    <section className="h-screen w-sidebar bg-black transition-all duration-300 ease-linear">
      {/* HEADER */}
      <div className="border-b border-white border-opacity-20 p-5 flex items-center">
        <div className="w-16 h-16 rounded-md bg-white p-1">
          <img
            src="https://www.insurancecareershq.com/_next/static/media/district-58-logo.aa4778f6.png"
            className="w-16 object-contain"
            alt="Farmers Insurance"
          />
        </div>
      </div>

      {/* NAVIGATION OPTIONS */}
      <div className="h-[85vh] flex flex-col justify-between">
        <div className="p-5">
          {/* MANAGE QUERIES */}
          <div className="mt-0">
            <Link
              to={"/contact-us"}
              className="w-full group flex items-center gap-3"
            >
              <IoHeadsetOutline
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/contact-us" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-proxima-semibold text-sidebarText ${pathname ===
                    "/contact-us" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Contact Us
                </p>
              </div>
            </Link>
            <Link
              to={"/career-leads"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <FaBookMedical
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/career-leads" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-proxima-semibold text-sidebarText ${pathname ===
                    "/career-leads" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Career Leads
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
