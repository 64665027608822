/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";

import ReactImageUploading from "react-images-uploading";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* REACT ICONS */
import { AiFillPlusCircle } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
/* ICONS */
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* USER DEFINED COMPONENTS */
import { Editor } from "react-draft-wysiwyg";
import InputField from "../../../components/input";
import { getUsersDetailsById, postUserDetails } from "../../../service/api";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email(),
  phoneNumber: yup.string(),
  designation: yup.string(),
  dob: yup.string(),
  address: yup.array(),
  role: yup.object(),
  organization: yup.object(),
  tags: yup.array(),
});

const EditBlog = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [profileImage, setProfileImage] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  /* CHOOSE IMAGES */
  const onImageChange = (imageList) => {
    setProfileImage(imageList);
  };

  // FORMIK REGISTRATION FUNCTION
  const formik = useFormik({
    initialValues: {
      blogTitle: "",
      category: [],
      blogDescription: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("firstName", values?.firstName);
      formData.append("lastName", values?.lastName);
      if (values?.address) {
        formData.append("address", JSON.stringify(values?.address));
      }
      if (values?.tags) {
        formData.append("tags", JSON.stringify(values?.tags));
      }
      if (values?.email) {
        formData.append("email", values?.email);
      }

      if (values?.phoneNumber) {
        formData.append("phoneNumber", values?.phoneNumber);
      }
      if (values?.designation) {
        formData.append("designation", values?.designation);
      }
      if (values?.dob) {
        formData.append("dob", values?.dob);
      }
      if (values?.role) {
        formData.append("role", values?.role?.value);
      }
      if (values?.organization) {
        formData.append("organization", values?.organization?.value);
      }

      if (profileImage?.[0]?.file) {
        formData.append("image", profileImage?.[0]?.file);
      }

      postUserDetails(formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            handleGetUserDetails();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          if (e?.response?.data?.message) {
            toast.error(e?.response?.data?.message);
          } else {
            toast.error(e?.message);
          }
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  /* HANDLE DELETE DATA */
  const handleGetUserDetails = () => {
    getUsersDetailsById(id)
      .then((res) => {
        toast.success(res?.message);
        setUserDetails(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Edit Blog | Farmers Insurance</title>
      </Helmet>

      <form onSubmit={formik.handleSubmit}>
        <div className="container-full-width">
          {/* HEADING */}
          <div className="block xl:flex lg:flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
                Edit Blog
              </h1>
              <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
                Edit organization member details for website (Change this!)
              </h2>
            </div>
            <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
              <Link to="/blogs">
                <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                  Back to Blogs
                </Button>
              </Link>
              <Button
                type="submit"
                disabled={loading}
                className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out"
              >
                {loading ? "Please wait..." : "Save Blog"}
              </Button>
            </div>
          </div>

          {/* FORM */}
          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
              {/* DETAILS */}
              <div className="col-span-2">
                <div className="bg-white rounded-lg border border-authBorder">
                  {/* <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Organization Member Details
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Edit these organization member specific details
                    </p>
                  </div> */}
                  <div className="p-5">
                    <form>
                      <div className="grid grid-cols-1 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                        {/* FIRSTNAME */}
                        <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Blog Title <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="blogTitle"
                                id="blogTitle"
                                value={formik.values.blogTitle}
                                onChange={formik.handleChange}
                                error={
                                  formik.errors.blogTitle &&
                                  formik.touched.blogTitle
                                    ? true
                                    : false
                                }
                                placeholder="Write the Blog Title"
                              />
                            </div>
                          </div>
                        </div>

                        {/* CATEGORY */}
                        <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Category <span className="text-red-500">*</span>
                            </label>
                            <div className="relative mt-1">
                              <Select
                                options={categoryList}
                                placeholder="Select Category"
                                className={`${formik.errors.category &&
                                  "error"}`}
                                classNamePrefix="dropdown"
                                name="category"
                                id="category"
                                value={formik.values.category}
                                onChange={(e) => {
                                  formik.setFieldValue("category", e);
                                }}
                              />

                              {formik.errors.category &&
                                formik.touched.category && (
                                  <div className="absolute top-3 right-8">
                                    <AiOutlineExclamationCircle className="w-5 h-5 text-red-500" />
                                  </div>
                                )}

                              {formik.values.category !== "" && (
                                <div className="absolute top-3 right-8">
                                  <FaRegCircleCheck className="w-5 h-5 text-green-500" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-span-6">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Blog Description{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Editor
                                toolbar={{ options: ["inline", "link"] }}
                                editorClassName="text-editor"
                                wrapperClassName="text-editor-wrapper"
                                placeholder="Write the blog description here"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* BLOG COVER IMAGE  */}
              <div className="col-span-1">
                {/* IMAGES */}
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Blog Cover Image
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Attach the profile images for this blog
                    </p>
                  </div>
                  <div className="p-5">
                    <ReactImageUploading
                      multiple
                      value={profileImage}
                      onChange={onImageChange}
                      maxNumber={5}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, onImageRemove }) => (
                        <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                          <div className="col-span-1">
                            <div
                              className="min-w-36  h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                              onClick={onImageUpload}
                            >
                              <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                              <div className="mt-2">
                                <p className="text-xs font-proxima-semibold text-greyText text-center">
                                  Click here to upload images
                                </p>
                                <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                  (JPG, JPEG, PNG)
                                </p>
                              </div>
                            </div>
                          </div>
                          {imageList.map((image, index) => (
                            <div className="col-span-1 relative" key={index}>
                              <img
                                src={image["data_url"]}
                                className="w-full h-productImage object-cover rounded-md"
                                alt="Product"
                              />
                              <div className="absolute top-2 right-2">
                                <Button
                                  className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <FiTrash className="w-3 h-3 text-black" />
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default EditBlog;
