/* REACT IMPORTS */
import { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";

/* STYLES */
import "../styles/global.css";

/* LAYOUT */
import Layout from "../layout";

/* PAGES */
import AuthLogin from "../pages/auth/login";
import AuthRegister from "../pages/auth/register";

import AddBlogs from "../pages/dashboard/blogs/add-blog";
import EditBlogs from "../pages/dashboard/blogs/edit-blog";
import Blogs from "../pages/dashboard/blogs/index";
import BlogDetails from "../pages/dashboard/blogs/view-blog";

import Dashboard from "../pages/dashboard";

import CmsList from "../pages/dashboard/cms/index";
import CmsView from "../pages/dashboard/cms/view-page/index";

import CareerLeads from "../pages/dashboard/career-leads";

import ContactUs from "../pages/dashboard/contact-us";

import ViewDetails from "pages/view-details";
import NewsLetter from "../pages/dashboard/newsLetter";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<AuthLogin />} />

          <Route path="/register" element={<AuthRegister />} />

          <Route
            path="/view-details"
            element={
              <Layout>
                <ViewDetails />
              </Layout>
            }
          />

          {/* DASHBOARD */}
          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />

          {/* BLOGS */}
          <Route
            path="/blogs"
            element={
              <Layout>
                <Blogs />
              </Layout>
            }
          />
          <Route
            path="/blog/view-blog/:id"
            element={
              <Layout>
                <BlogDetails />
              </Layout>
            }
          />
          <Route
            path="/blog/edit-blog/:id"
            element={
              <Layout>
                <EditBlogs />
              </Layout>
            }
          />
          <Route
            path="/blog/add-blog"
            element={
              <Layout>
                <AddBlogs />
              </Layout>
            }
          />

          {/* CONTACT US */}
          <Route
            path="/contact-us"
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />

          {/* NEWSLETTER */}
          <Route
            path="/newsletter"
            element={
              <Layout>
                <NewsLetter />
              </Layout>
            }
          />

          {/* CAREER LEADS */}
          <Route
            path="/career-leads"
            element={
              <Layout>
                <CareerLeads />
              </Layout>
            }
          />
          {/* <Route
            path="/career-leads/view-career-leads/:id"
            element={
              <Layout>
                <ViewMedicalAid />
              </Layout>
            }
          />
          <Route
            path="/career-leads/edit-career-leads/:id"
            element={
              <Layout>
                <EditMedicalAid />
              </Layout>
            }
          />

          <Route
            path="/career-leads/export-career-leads/:id"
            element={
              <ExportMedicalAid />
            }
          /> */}



          <Route
            path="/cms/"
            element={
              <Layout>
                <CmsList />
              </Layout>
            }
          />
          <Route
            path="/cms/view/:pageName"
            element={
              <Layout>
                <CmsView />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
