/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Helmet } from "react-helmet";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ICONS */
import { FaCalendar } from "react-icons/fa6";

/* USER DEFINED COMPONENTS */
import BlogCard from "components/blogs/BlogCard";
import InputField from "components/input";
import { Link } from "react-router-dom";
import { deleteBlog, editBlogById, getBlogList } from "service/api";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const Blogs = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [BlogsList, setBlogsList] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalDocs, setTotalDocs] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  /* DATEPICKER VARIABES */
  const [datepicker, setDatepicker] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date().setMonth(new Date().getMonth() - 1)).format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );

  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getBlogListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getBlogListFunction();
  };

  const getBlogListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
    };
    if (search) {
      params.search = search;
    }

    /* HANDLE GET BLOG DATA */
    getBlogList(params)
      .then((res) => {
        setBlogsList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("Error: ", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = (deleteId) => {
    deleteBlog(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getBlogListFunction();
      });
  };

  /* HANDLE STATUS CHANGE FUNCTION */
  const handleStatusChange = (editId) => {
    editBlogById(editId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        getBlogListFunction();
      });
  };

  /* USE-EFFECTS */
  useEffect(() => {
    getBlogListFunction();
  }, [page, sizePerPage, search]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Members | Farmers Insurance</title>
      </Helmet>

      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-center justify-between">
          {/* HEADING */}
          <div>
            <h1 className="text-2xl font-proxima-bold text-black tracking-tight">
              Blogs
            </h1>
            <h2 className="text-base font-proxima-regular text-greyText">
              Manage all the blogs
            </h2>
          </div>

          {/* SEARCH AND FILTERS */}
          <div className="block xl:flex lg:flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            {/* SEARCHBAR */}
            <div className="w-full xl:w-searchBar lg:w-searchBar">
              <InputField
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => {
                  setSearch(e?.target?.value);
                }}
              />
            </div>

            {/* RANGEPICKER */}
            <div className="relative mt-2 xl:mt-0 lg:mt-0 w-full xl:w-auto lg:w-auto">
              <div
                ref={domNode}
                className="w-full xl:w-rangePicker lg:w-rangePicker flex items-center gap-3 border border-authBorder rounded-lg py-3 px-5 bg-white cursor-pointer"
              >
                <FaCalendar className="w-4 h-4 text-greyText" />
                <input
                  type="text"
                  name="dateRange"
                  placeholder="Select the date range"
                  value={`${startDate} - ${endDate}`}
                  onClick={() => setDatepicker(!datepicker)}
                  className="w-full bg-white outline-none font-proxima-semibold text-sm focus:outline-none active:outline-none placeholder:font-proxima-regular transition-all duration-300 ease-linear text-black"
                />
              </div>
              {datepicker && (
                <div className="absolute top-12 right-0 transition-all duration-300 ease-linear z-50">
                  <DateRangePicker
                    ranges={[selectionRange]}
                    dragSelectionEnabled
                    showPreview
                    onChange={(e) => {
                      setStartDate(
                        moment(e.selection.startDate).format("DD/MM/YYYY")
                      );
                      setEndDate(
                        moment(e.selection.endDate).format("DD/MM/YYYY")
                      );
                    }}
                    className="shadow-xl font-proxima-regular rounded-xl border border-authBorder cursor-pointer"
                  />
                </div>
              )}
            </div>

            {/* ADD Blog */}
            <Link to="/blog/add-blog" target="_blank">
              <Button className="w-full xl:w-auto lg:w-auto mt-2 xl:mt-0 lg:mt-0 bg-logoYellow rounded-lg py-3 px-8 text-sm font-proxima-semibold shadow-none hover:shadow-none normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                Add Blog
              </Button>
            </Link>
          </div>
        </div>

        <div className="mt-10">
          {loading ? (
            <LoadingScreen />
          ) : BlogsList.length < 1 ? (
            <NoDataScreen />
          ) : (
            <div className="grid gap-4 lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1">
              {BlogsList.map((blog) => (
                <BlogCard key={blog._id} blog={blog} />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
