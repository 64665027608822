/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import { useEffect, useState } from "react";
import { toast } from "react-toast";

/* FORM CONTROL */
import { useFormik } from "formik";
import * as yup from "yup";

/* USER DEFINED COMPONENTS */
import AboutUs from "components/cms/homepage/AboutUs";
import Counters from "components/cms/homepage/Counters";
import Hero from "components/cms/homepage/Hero";

/* API COMPONENTS */
import { useDispatch } from "react-redux";
import actions from "redux/actions/userAction";
import { editCmsDetailsById, getCmsList } from "service/api";

/* Validation Schema */
const validationSchema = yup.object().shape({
  pageName: yup.string().required("Tag Name is required."),
  moduleName: yup.string().required("Tag Name is required."),
  moduleType: yup.string().required("Tag Name is required."),
  redirectURL: yup.string(),
  description: yup.string(),
  iframe: yup.string(),
  sourceUrl: yup.string(),
  previousUrl: yup.string(),
});

/* OPTIONS */
const options = [
  { label: "Url", value: "url" },
  { label: "Upload", value: "upload" },
];

const moduleTypeOptions = [
  { label: "paragraph", value: "paragraph" },
  { label: "anchorTag", value: "anchorTag" },
  { label: "image", value: "image" },
  { label: "video", value: "video" },
  { label: "iframe", value: "iframe" },
];

const HomePage = (props) => {
  const dispatch = useDispatch();

  const [cmsList, setCmsList] = useState([]);
  const [editDialog, setEditDialog] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editDetails, setEditDetails] = useState(null);
  const [editTagFormLoading, setEditTagFormLoading] = useState(false);
  const [sourceType, setSourceType] = useState(null);
  const [tagImages, setTagImages] = useState([]);

  /* FUNCTION FOR ADDING TAGS */
  const editFormik = useFormik({
    initialValues: {
      pageName: "",
      moduleName: "",
      moduleType: "",
      redirectURL: "",
      description: "",
      iframe: "",
      sourceUrl: "",
      previousUrl: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("pageName", values?.pageName);
      formData.append("moduleName", values?.moduleName);
      formData.append("moduleType", values?.moduleType);

      if (values?.redirectURL) {
        formData.append("redirectURL", values?.redirectURL);
      }
      if (values?.description) {
        formData.append("description", values?.description);
      }
      if (values?.iframe) {
        formData.append("iframe", values?.iframe);
      }
      if (values?.sourceUrl) {
        formData.append("sourceUrl", values?.sourceUrl);
      }
      if (values?.previousUrl) {
        formData.append("previousUrl", values?.previousUrl);
      }

      setEditTagFormLoading(true);
      editCmsDetailsById(editId, formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            editFormik.handleReset();
            setTagImages([]);
            setEditDialog(false);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setEditTagFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          fetchCmsData();
          setEditTagFormLoading(false);
        });
    },
  });

  /* CHOOSE IMAGES */
  const onImageChange = (imageList) => {
    setTagImages(imageList);
  };

  const fetchCmsData = () => {
    dispatch(actions.setLoading(true));
    getCmsList({ pagination: false, pageName: "home-page" })
      .then((res) => {
        setCmsList(res?.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      })
      .finally(() => {
        dispatch(actions.setLoading(false));
      });
  };

  useEffect(() => {
    fetchCmsData();
  }, []);

  useEffect(() => {
    if (editDetails) {
      editFormik.setFieldValue("pageName", editDetails?.pageName);
      editFormik.setFieldValue("moduleName", editDetails?.moduleName);
      editFormik.setFieldValue("moduleType", editDetails?.moduleType);
      editFormik.setFieldValue("redirectURL", editDetails?.redirectURL);
      editFormik.setFieldValue("description", editDetails?.description);
      if (editDetails?.source?.url) {
        editFormik.setFieldValue("previousUrl", editDetails?.source?.url);
      }
    }
  }, [editDetails]);

  return (
    <div className={props?.loading ? "hidden" : `w-full`}>
      {/* BANNER */}
      <Hero
        cmsList={cmsList}
        setEditDetails={setEditDetails}
        setEditId={setEditId}
        setEditDialog={setEditDialog}
        editDialog={editDialog}
      />

      {/* ABOUT US */}
      <div className="py-10">
        <AboutUs
          cmsList={cmsList}
          setEditDetails={setEditDetails}
          setEditId={setEditId}
          setEditDialog={setEditDialog}
          editDialog={editDialog}
        />
      </div>

      {/* COUNTERS */}
      <div className="pb-10">
        <Counters
          cmsList={cmsList}
          setEditDetails={setEditDetails}
          setEditId={setEditId}
          setEditDialog={setEditDialog}
          editDialog={editDialog}
        />
      </div>
    </div>
  );
};

export default HomePage;
