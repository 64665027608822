/* REACT IMPORTS */
import React from "react";
import ReactDOM from "react-dom/client";

/* MATERIAL TAILWIND THEME PROVIDER */
import { ThemeProvider } from "@material-tailwind/react";

/* TOAST NOTIFICATION */
import { ToastContainer } from "react-toast";

import { Provider } from "react-redux";

/* MAIN APPLICATION */
import Routes from "./routes/routes";

import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <Routes />
        <ToastContainer theme="light" position="top-right" delay="5000" />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
