/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import moment from "moment";
import DataTable from "react-data-table-component";
import Input from "../../../components/input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { FiEdit, FiTrash } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { MdOutlineRemoveRedEye } from "react-icons/md";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import {
  deleteContactUsDetailsById,
  editContactusDetailsById,
  getContactUsList,
} from "../../../service/api";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";

const ContactUs = () => {
  const { id } = useParams();

  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [addDialog, setAddDialog] = useState(false);
  const [contactUsList, setContactUsList] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);

  const [totalDocs, setTotalDocs] = useState([]);
  const [addContactusFormLoading, setAddContactusFormLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [editModal, setEditModal] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editContactusId, setEditContactusId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getContactUsListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getContactUsListFunction();
  };

  const getContactUsListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
    };
    if (search) {
      params.search = search;
    }

    getContactUsList(params)
      .then((res) => {
        setContactUsList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("Error: ", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteContactUsDetailsById(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getContactUsListFunction();
      });
  };

  const handleEditContactus = (values) => {
    console.log(values);
    const formData = new FormData();

    formData.append("name", values?.name);
    formData.append("email", values?.email);
    formData.append("phoneNumber", values?.phoneNumber);
    formData.append("message", values?.message);
    formData.append("status", values?.status);

    setAddContactusFormLoading(true);

    editContactusDetailsById(editContactusId, formData)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
          addContactusFormik.handleReset();
          setAddDialog(false);
          setEditDialog(false);
          setEditContactusId(null);
        } else {
          console.log(res?.message);
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message || "An error occurred");
      })
      .finally(() => {
        getContactUsListFunction();
        setAddContactusFormLoading(false);
      });
  };

  const addContactusFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      status: 1,
    },
    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      phoneNumber: yup.string().required("Phone Number is required"),
      message: yup.string().required("Message is required"),
      status: yup
        .number()
        .oneOf([0, 1], "Status must be 0 or 1")
        .required("Status is required"),
    }),
    onSubmit: (values) => {
      handleEditContactus(values);
    },
  });

  {
    console.log(
      "%c Line:145 🥓 addContactusFormik",
      "color:#b03734",
      addContactusFormik
    );
  }
  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      grow: 0.4,
      selector: (row, index) => (
        <span className="ml-2 text-sm font-proxima-semibold text-black text-opacity-60">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Name",
      selector: (row) => (
        <div className="flex items-center gap-2">
          <p className="text-sm font-proxima-semibold text-black text-opacity-60">
            {row.name}
          </p>
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.email}
        </span>
      ),
    },
    {
      name: "Phone",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.phoneNumber}
        </span>
      ),
    },
    {
      name: "Date & Time",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {moment(row.createdAt).format("MMM DD, YYYY hh:mm a")}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip content="View" className="text-sm font-proxima-semibold">
            <Button
              onClick={() => {
                setSelectedContact(row);
                setViewModal(!viewModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <MdOutlineRemoveRedEye className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>
          <Tooltip content="Edit" className="text-sm font-proxima-semibold">
            <Button
              onClick={() => {
                setEditContactusId(row?._id);
                setEditDialog(true);
                addContactusFormik.setValues({
                  name: row?.name,
                  email: row?.email,
                  phoneNumber: row?.phoneNumber,
                  message: row?.message,
                  status: 1,
                });
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiEdit className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>

          <Tooltip content="Delete" className="text-sm font-proxima-semibold">
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiTrash className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getContactUsListFunction();
  }, [page, sizePerPage, search]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Contact Us Queries | Farmers Insurance</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-proxima-bold text-black tracking-tight">
              Contact Us
            </h1>
          </div>
          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-[40vh]">
              <Input
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? contactUsList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>
        {/* EDIT CONTACT US */}
        <Dialog
          open={editDialog}
          handler={() => setEditDialog(!editDialog)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <form onSubmit={addContactusFormik.handleSubmit}>
              <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
                <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                  Edit Contact
                </h5>
                <Button
                  className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                  onClick={() => setEditDialog(!editDialog)}
                >
                  <IoClose className="w-4 h-4 text-greyText" />
                </Button>
              </div>

              <div className="p-5 border-b border-authBorder">
                {/* Name */}
                <div className="form-group">
                  <label className="text-sm font-proxima-semibold text-black">
                    Name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      onChange={addContactusFormik.handleChange}
                      value={addContactusFormik.values.name}
                      error={
                        addContactusFormik.errors.name &&
                        addContactusFormik.touched.name
                          ? true
                          : false
                      }
                      placeholder="Write your name"
                    />
                  </div>
                </div>

                {/* Email */}
                <div className="form-group mt-3">
                  <label className="text-sm font-proxima-semibold text-black">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      onChange={addContactusFormik.handleChange}
                      value={addContactusFormik.values.email}
                      error={
                        addContactusFormik.errors.email &&
                        addContactusFormik.touched.email
                          ? true
                          : false
                      }
                      placeholder="Write your email"
                    />
                  </div>
                </div>

                {/* Phone Number */}
                <div className="form-group mt-3">
                  <label className="text-sm font-proxima-semibold text-black">
                    Phone Number <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <Input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      onChange={addContactusFormik.handleChange}
                      value={addContactusFormik.values.phoneNumber}
                      error={
                        addContactusFormik.errors.phoneNumber &&
                        addContactusFormik.touched.phoneNumber
                          ? true
                          : false
                      }
                      placeholder="Write your phone number"
                    />
                  </div>
                </div>

                {/* Message */}
                <div className="form-group mt-3">
                  <label className="text-sm font-proxima-semibold text-black">
                    Message <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <Input
                      type="text"
                      name="message"
                      id="message"
                      onChange={addContactusFormik.handleChange}
                      value={addContactusFormik.values.message}
                      error={
                        addContactusFormik.errors.message &&
                        addContactusFormik.touched.message
                          ? true
                          : false
                      }
                      placeholder="Write your message"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-end px-5 py-3">
                <Button
                  disabled={addContactusFormLoading}
                  type="submit"
                  className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case"
                >
                  {addContactusFormLoading ? "Please wait.." : "Save"}
                </Button>
              </div>
            </form>
          </DialogBody>
        </Dialog>

        {/* DELETE CONTACT US */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                Delete
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-proxima-regular text-black">
                Are you sure you want to remove this details?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* VIEW CONTACT US */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="sm" // Adjusted the size to "sm"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                User Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              {selectedContact && (
                <div>
                  <p className="text-base font-proxima-semibold text-black mb-4">
                    <strong className="font-proxima-regular">Name:</strong>{" "}
                    {selectedContact.name}
                  </p>
                  <p className="text-base font-proxima-semibold text-black mb-4">
                    <strong className="font-proxima-regular">Email:</strong>{" "}
                    {selectedContact.email}
                  </p>
                  <p className="text-base font-proxima-semibold text-black mb-4">
                    <strong className="font-proxima-regular">
                      Phone Number:
                    </strong>{" "}
                    {selectedContact.phoneNumber}
                  </p>
                  <p className="text-base font-proxima-semibold text-black mb-4">
                    <strong className="font-proxima-regular">Message:</strong>{" "}
                    {selectedContact.message}
                  </p>

                  <p className="text-base font-proxima-semibold text-black">
                    <strong className="font-proxima-regular">Timestamp:</strong>{" "}
                    {moment(selectedContact.createdAt).format(
                      "MMM DD, YYYY hh:mm a "
                    )}
                  </p>
                </div>
              )}
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default ContactUs;
