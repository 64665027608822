const orders = [
  {
    orderID: "#001",
    product: "Organic Brown Rice",
    name: "Hittarth Solanki",
    email: "hittarth911@gmail.com",
    phone: "+91 9756389851",
    purchaseDate: "12/03/2024",
    amount: "1160.56",
    paymentStatus: "Cash on Delivery",
  },
  {
    orderID: "#002",
    product: "Cold-Pressed Virgin Coconut Oil",
    name: "Darsh Shah",
    email: "shahdarsh364@gmail.com",
    phone: "+91 9756389861",
    purchaseDate: "14/03/2024",
    amount: "2890.3",
    paymentStatus: "Payment Completed",
  },
];

const invoices = [
  {
    orderID: "HDFC001",
    invoiceDate: "12/03/2024",
    amount: "1160.56",
    paymentStatus: "Cash on Delivery",
  },
];

export { orders, invoices };
