import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const SplineChart = () => {
  const [state] = useState({
    series: [
      {
        name: "Total Orders",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: "Cancelled Orders",
        data: [11, 32, 45, 32, 34, 52, 41],
      },
    ],
    options: {
      chart: {
        height: "100%",
        type: "area",
        fontFamily: "ProximaNova-Regular, sans-serif",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      legend: {
        show: false,
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2023-09-19",
          "2023-09-20",
          "2023-09-21",
          "2023-09-22",
          "2023-09-23",
          "2023-09-24",
          "2023-09-25",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });

  return (
    <div class="spline-chart">
      <ReactApexChart
        series={state.series}
        options={state.options}
        height="100%"
        type="area"
      />
    </div>
  );
};

const PieChart = () => {
  const [state] = useState({
    series: [275, 776, 76],
    options: {
      chart: {
        type: "donut",
        fontFamily: "ProximaNova-Regular, sans-serif",
      },
      labels: ["Cancelled orders", "Completed orders", "Processing orders"],
      legend: {
        position: "bottom",
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  return (
    <div class="pie-chart">
      <ReactApexChart
        series={state.series}
        options={state.options}
        height="100%"
        type="donut"
      />
    </div>
  );
};

const PieChartForTopSellingCategory = () => {
  const [state] = useState({
    series: [10, 3, 5, 7],
    options: {
      chart: {
        type: "donut",
        fontFamily: "ProximaNova-Regular, sans-serif",
      },
      labels: ["Healthy Snack", "Ayurvedic", "Shake and Smoothies", "Yoghurts and Cheese"],
      legend: {
        position: "bottom",
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  return (
    <div class="pie-chart">
      <ReactApexChart
        series={state.series}
        options={state.options}
        height="100%"
        type="donut"
      />
    </div>
  );
};

export { SplineChart, PieChart, PieChartForTopSellingCategory };
