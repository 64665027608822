/* eslint-disable no-unused-vars */
/* ICONS */
import { IoHeadsetOutline } from "react-icons/io5";

const navMenu = [
  // {
  //   id: 1,
  //   link: "/dashboard",
  //   icon: MdSpaceDashboard,
  //   name: "Sales Dashboard",
  // },
  // {
  //   id: 2,
  //   link: "/customers",
  //   icon: BsEmojiSmile,
  //   name: "Customers",
  // },
  // {
  //   id: 3,
  //   link: "/organization",
  //   icon: MdOutlineInventory2,
  //   name: "Organization",
  //   secondary: "/organization/add-product",
  // },
  // {
  //   id: 4,
  //   link: "/categories",
  //   icon: BiCategory,
  //   name: "Categories",
  // },
  // {
  //   id: 6,
  //   link: "/sub-categories",
  //   icon: TbCategoryPlus,
  //   name: "Sub-categories",
  // },
  // {
  //   id: 7,
  //   link: "/tags",
  //   icon: LuTags,
  //   name: "Tags",
  // },
  /* {
    id: 8,
    link: "/instagram-reels",
    icon: FaInstagram,
    name: "Instagram Reels",
  }, */
  // {
  //   id: 9,
  //   link: "/offers-and-promocodes",
  //   icon: RiCoupon2Line,
  //   name: "Offers & Promocodes",
  // },
  {
    id: 1,
    link: "/contact-us",
    icon: IoHeadsetOutline,
    name: "Contact Us",
  },
  {
    id: 2,
    link: "/career-leads",
    icon: IoHeadsetOutline,
    name: "Career Leads",
  },
];


export default navMenu;
