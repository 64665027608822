import { Helmet } from "react-helmet";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

const BlogDetails = () => {
  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Blog Details | Farmers Insurance</title>
      </Helmet>

      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-center justify-between">
          {/* HEADING */}
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Blog Details
            </h1>
            <h2 className="text-lg font-proxima-regular text-greyText tracking-tight">
              Manage the member details, member confirmation and delivery
              details have been sent to{" "}
              <span className="font-proxima-bold">
                hittarth@roundtechsquare.com
              </span>
            </h2>
          </div>

          {/* VIEW INVOICE */}
          <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
              Back to Blogs
            </Button>
            {/* <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal justify-center shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out flex">
              <TbFileInvoice className="w-5 h-5 mr-1" /> View Invoice
            </Button> */}
          </div>
        </div>

        {/* CUSTOMERS LIST */}
        <div className="mt-5 xl:mt-5 lg:mt-5">
          <div className=" bg-white border border-authBorder rounded-md overflow-y-auto overflow-x-hidden hide-scrollbar">
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 h-full">
              <div className="col-span-3 h-full order-2 xl:order-1 lg:order-1">
                <div className="border-r border-authBorder h-full">
                  <div className="p-5 xl:p-5 lg:p-5">
                    <div className="grid grid-cols-12 h-full gap-5 pb-3 ">
                      {/* SHIPPING INFORMATION */}
                      <div className="col-span-12 lg:col-span-6 px-3 py-3 ">
                        <h2 className="text-xl font-proxima-bold text-black">
                          Shipping details
                        </h2>
                        <h3 className="text-base font-proxima-regular text-greyText border-b border-authBorder pb-2">
                          Shipping details include recipient information
                          necessary for smooth and efficient member delivery.
                        </h3>

                        <p className="text-base font-proxima-regular text-greyText mt-3">
                          Hittarth Solanki
                        </p>
                        <p className="text-base font-proxima-regular text-greyText">
                          hittarth@roundtechsquare.com
                        </p>
                        <p className="text-base font-proxima-regular text-greyText">
                          +917265849365
                        </p>
                        <p className="text-base font-proxima-regular text-greyText mt-2">
                          369 Heritage Overlook
                        </p>
                        <p className="text-base font-proxima-regular text-greyText ">
                          Woodstock, GA, 30188, United States
                        </p>

                        <h3 className="text-xl font-proxima-bold text-black border-b border-authBorder pb-2 mt-5">
                          Blog information
                        </h3>

                        <p className="text-base font-proxima-regular text-greyText mt-2">
                          <span className="text-black font-proxima-bold">
                            Shipping Type :
                          </span>{" "}
                          Ground Advantage - Package{" "}
                          <span className="text-black font-proxima-bold">
                            ( ₹80.00 )
                          </span>
                        </p>
                        <p className="text-lg font-proxima-regular text-[#FFB129]  mt-2">
                          <span className="text-base text-black font-proxima-bold">
                            Blog Status :
                          </span>{" "}
                          Payment Received & Blog Placed
                        </p>
                      </div>

                      {/* BILLING INFORMATION */}
                      <div className="col-span-12 lg:col-span-6 px-3 py-3">
                        <h2 className="text-xl font-proxima-bold text-black">
                          Billing details
                        </h2>
                        <h3 className="text-base font-proxima-regular text-greyText border-b border-authBorder pb-2">
                          Billing details, including payment method and
                          transaction specifics, ensure accurate and complete
                          order processing.
                        </h3>

                        <p className="text-base font-proxima-regular text-greyText mt-3">
                          Hittarth Solanki
                        </p>
                        <p className="text-base font-proxima-regular text-greyText">
                          hittarth@roundtechsquare.com
                        </p>
                        <p className="text-base font-proxima-regular text-greyText">
                          +917265849365
                        </p>
                        <p className="text-base font-proxima-regular text-greyText mt-2">
                          369 Heritage Overlook
                        </p>
                        <p className="text-base font-proxima-regular text-greyText ">
                          Woodstock, GA, 30188, United States
                        </p>

                        <h3 className="text-xl font-proxima-bold text-black border-b border-authBorder pb-2 mt-5">
                          Payment information
                        </h3>

                        <p className="text-base font-proxima-regular text-greyText mt-2">
                          <span className="text-black font-proxima-bold">
                            Payment Method :
                          </span>{" "}
                          RAZORPAY{" "}
                          <span className="text-black font-proxima-bold">
                            ( ID : 0d99cd87 )
                          </span>
                        </p>
                        <p className=" text-lg font-proxima-regular text-green-700 mt-2">
                          <span className="text-base text-black font-proxima-bold">
                            Payment Status :
                          </span>{" "}
                          Payment Received
                        </p>
                      </div>
                    </div>

                    {/* PRODUCTS */}
                    <div className="grid grid-cols-12 h-full gap-5 pt-3 border-t border-authBorder ">
                      {/* SHIPPING INFORMATION */}
                      <div className="col-span-12 px-3 py-3">
                        <h2 className="text-xl font-proxima-bold text-black">
                          Products (2)
                        </h2>
                        {/* <h3 className="text-base font-proxima-regular text-greyText  pb-2">
                                                Shipping details include recipient information necessary for smooth and efficient order delivery.
                                            </h3> */}

                 
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-1 h-full order-1 xl:order-2 lg:order-2">
                <div className="p-5 xl:p-5 lg:p-5">
                  <div className="grid grid-cols-6 border-b border-authBorder px-0 py-5 xl:px-2 lg:px-2">
                    <div className="col-span-4">
                      <h2 className="text-xl font-proxima-bold text-black">
                        Blog ID #00014054
                      </h2>
                      <h3 className=" text-sm font-proxima-regular text-greyText  pb-2">
                        17 January 2023, 11:24 PM
                      </h3>
                    </div>
                    <div className="col-span-2 mt-3">
                      <span class="bg-green-100  text-green-800 text-sm font-medium me-2 px-2.5 py-2 rounded dark:bg-green-900 dark:text-green-300">
                        Delivered
                      </span>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="bg-greyBg rounded-md w-full py-2 px-5">
                      <p className="text-sm font-proxima-semibold text-greyText">
                        Summary
                      </p>
                    </div>

                    {/* SUBTOTAL */}
                    <div className="grid grid-cols-2 border-b border-authBorder px-5 py-5 xl:p-5 lg:p-5">
                      <div className="col-span-1">
                        <p className="text-base font-proxima-regular text-greyText">
                          Subtotal
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-base font-proxima-bold text-black">
                          ₹1,650.00
                        </p>
                      </div>
                    </div>

                    {/* DISCOUNT */}
                    <div className="grid grid-cols-2 border-b border-authBorder px-5 py-5 xl:p-5 lg:p-5">
                      <div className="col-span-1">
                        <p className="text-base font-proxima-regular text-greyText">
                          Discount
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-base font-proxima-bold text-black">
                          ₹1,650.00
                        </p>
                      </div>
                    </div>

                    {/* SHIPPING COST */}
                    <div className="grid grid-cols-2 border-b border-authBorder px-5 py-5 xl:p-5 lg:p-5">
                      <div className="col-span-1">
                        <p className="text-base font-proxima-regular text-greyText">
                          Shipment cost
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-base font-proxima-bold text-black">
                          ₹1,650.00
                        </p>
                      </div>
                    </div>

                    {/* GRAND TOTAL */}
                    <div className="grid grid-cols-2  px-5 py-5 xl:p-5 lg:p-5">
                      <div className="col-span-1">
                        <p className="text-lg font-proxima-bold text-black">
                          Grand Total
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-lg font-proxima-bold text-black">
                          ₹1,650.00
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="bg-greyBg rounded-md w-full py-2 px-5">
                      <p className="text-sm font-proxima-semibold text-greyText">
                        Notes
                      </p>
                    </div>
                    <p className="text-base font-proxima-regular p-3 text-greyText">
                      Please ship order fast
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BlogDetails;
