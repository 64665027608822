/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";

import DataTable from "react-data-table-component";
import Input from "../../../components/input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { BiEdit } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import { useFormik } from "formik";
import * as yup from "yup";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import { IMAGE_URL } from "config";
import { FiTrash } from "react-icons/fi";
import {
  deleteCareerListDetailsById,
  editCareerDetails,
  getCareerList,
} from "../../../service/api";

const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  phoneNumber: yup.string().required(),
  city: yup.string().required(),
  message: yup.string().required(),
  status: yup.number().required(),
});

const CareerLeads = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [careerList, setCareerList] = useState([]);
  const [totalDocs, setTotalDocs] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [editCareerId, setEditCareerId] = useState(null);
  console.log("%c Line:65 🍕 editCareerId", "color:#3f7cff", editCareerId);
  const [attachedFileUrl, setAttachedFileUrl] = useState(null);

  /* FUNCTIONS */
  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      grow: 0.4,
      selector: (row, index) => (
        <span className="ml-2 text-sm font-proxima-semibold text-black text-opacity-60">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Name",
      grow: 1.5,
      selector: (row) => (
        <div className="flex items-center gap-2">
          <p className="text-sm font-proxima-semibold text-black text-opacity-60">
            {row.firstName} {row?.lastName}
          </p>
        </div>
      ),
    },
    {
      name: "Email",
      // width: "25vh",
      grow: 2.3,
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.email}
        </span>
      ),
    },
    {
      name: "Phone",
      grow: 1.8,
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.phoneNumber}
        </span>
      ),
    },
    {
      name: "Date & Time",
      grow: 1.8,
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {moment(row.createdAt).format("MMM DD, YYYY hh:mm a ")}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center -ml-2">
          <Tooltip content="View" className="text-sm font-proxima-semibold">
            <Button
              onClick={() => {
                setSelectedRow(row);
                setViewModal(true);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <MdOutlineRemoveRedEye className="w-5 h-5 text-gray-600" />
            </Button>
          </Tooltip>
          <Tooltip content="Edit" className="text-sm font-proxima-semibold">
            <Button
              onClick={() => {
                setEditCareerId(row?._id); // Set row data for editing
                setEditModal(true);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <BiEdit className="w-5 h-5 text-gray-600" />
            </Button>
          </Tooltip>
          <Tooltip content="Delete" className="text-sm font-proxima-semibold">
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiTrash className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handlePageChange = async (e) => {
    setPage(e);
    getCareerListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getCareerListFunction();
  };

  const getCareerListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
    };
    if (search) {
      params.search = search;
    }

    getCareerList(params)
      .then((res) => {
        setCareerList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("Error: ", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteCareerListDetailsById(deleteId)
      .then((res) => {
        toast.success("Details have been removed successfully.");
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getCareerListFunction();
      });
  };

  const editHandler = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      city: "",
      status: 1,
      message: "",
      attachedFile: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();

      if (values.firstName) formData.append("firstName", values.firstName);
      if (values.lastName) formData.append("lastName", values.lastName);
      if (values.email) formData.append("email", values.email);
      if (values.phoneNumber)
        formData.append("phoneNumber", values.phoneNumber);
      if (values.city) formData.append("city", values.city);
      formData.append("status", values.status);
      if (values.message) formData.append("message", values.message);
      if (values.attachedFile)
        formData.append("attachedFile", values.attachedFile);

      setLoading(true);
      editCareerDetails(editCareerId, formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res.message);

            editHandler.handleReset();

            setEditModal(false);

            getCareerListFunction();
          } else {
            toast.error(res.message);
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message || "An error occurred");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    console.log("useEffect triggered for CareerDetails");

    if (editCareerId && careerList && careerList.length > 0) {
      console.log("%c Line:266 🍬 careerList", "color:#42b983", careerList);
      const selectedCareer = careerList.find((obj) => obj._id === editCareerId);

      console.log("%c selectedCareer", "color:#33a5ff", selectedCareer);

      editHandler.setValues({
        firstName: selectedCareer?.firstName || "",
        lastName: selectedCareer?.lastName || "",
        email: selectedCareer?.email || "",
        phoneNumber: selectedCareer?.phoneNumber || "",
        city: selectedCareer?.city || "",
        status: selectedCareer?.status || 1,
        message: selectedCareer?.message || "",
        attachedFile: selectedCareer?.attachedFile || null,
      });

      if (selectedCareer?.attachedFile) {
        setAttachedFileUrl(`${IMAGE_URL}${selectedCareer.attachedFile}`);
      }
    }
  }, [careerList, editCareerId]);

  useEffect(() => {
    getCareerListFunction(page, sizePerPage, search);
  }, [page, sizePerPage, search]);

  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Career Leads | Farmers Insurance</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-proxima-bold text-black tracking-tight">
              Career Leads
            </h1>
            {/* <h2 className="text-base font-proxima-regular text-greyText">
              Manage the queries by your users from career.
            </h2> */}
          </div>
          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-[40vh]">
              <Input
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? careerList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>

        {/* Edit careers */}
        <Dialog
          open={editModal}
          handler={() => setEditModal(!editModal)}
          size="sm"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-5 bg-white">
            <div className="border-b border-authBorder pb-4 mb-4 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black">
                Edit Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setEditModal(!editModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <form onSubmit={editHandler.handleSubmit}>
              <div className="space-y-4">
                {/* FIRST NAME */}
                <div className="form-group">
                  <label className="text-sm font-proxima-semibold text-black">
                    First Name <span className="text-red-500">*</span>
                    <div className="mt-1">
                      <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name"
                        value={editHandler.values.firstName}
                        onChange={editHandler.handleChange}
                        errorHandler={
                          editHandler.errors.firstName &&
                          editHandler.touched.firstName
                        }
                      />
                    </div>
                  </label>
                </div>
                {/* LAST NAME */}
                <div className="form-group">
                  <label className="text-sm font-proxima-semibold text-black">
                    Last Name <span className="text-red-500">*</span>
                    <div className="mt-1">
                      <Input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        value={editHandler.values.lastName}
                        onChange={editHandler.handleChange}
                        errorHandler={
                          editHandler.errors.lastName &&
                          editHandler.touched.lastName
                        }
                      />
                    </div>
                  </label>
                </div>
                {/* EMAIL ADDRESS */}
                <div className="form-group">
                  <label className="text-sm font-proxima-semibold text-black">
                    Email address <span className="text-red-500">*</span>
                    <div className="mt-1">
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        value={editHandler.values.email}
                        onChange={editHandler.handleChange}
                        errorHandler={
                          editHandler.errors.email && editHandler.touched.email
                        }
                      />
                    </div>
                  </label>
                </div>
                {/* PHONE NUMBER */}
                <div className="form-group">
                  <label className="text-sm font-proxima-semibold text-black">
                    Phone Number <span className="text-red-500">*</span>
                    <div className="mt-1">
                      <Input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="Phone Number"
                        value={editHandler.values.phoneNumber}
                        onChange={editHandler.handleChange}
                        errorHandler={
                          editHandler.errors.phoneNumber &&
                          editHandler.touched.phoneNumber
                        }
                      />
                    </div>
                  </label>
                </div>
                {/* CITY */}
                <div className="form-group">
                  <label className="text-sm font-proxima-semibold text-black">
                    City <span className="text-red-500">*</span>
                    <div className="mt-1">
                      <Input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        value={editHandler.values.city}
                        onChange={editHandler.handleChange}
                        errorHandler={
                          editHandler.errors.city && editHandler.touched.city
                        }
                      />
                    </div>
                  </label>
                </div>
                {/* MESSAGE */}
                <div className="form-group">
                  <label className="text-sm font-proxima-semibold text-black">
                    Message <span className="text-red-500">*</span>
                    <div className="mt-1">
                      <Input
                        type="textarea"
                        name="message"
                        id="message"
                        placeholder="Message"
                        value={editHandler.values.message}
                        onChange={editHandler.handleChange}
                        errorHandler={
                          editHandler.errors.message &&
                          editHandler.touched.message
                        }
                      />
                    </div>
                  </label>
                </div>

                {/* SUBMIT BUTTON */}
                <div className="mt-4">
                  <Button
                    type="submit"
                    className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </DialogBody>
        </Dialog>

        {/* delete careers */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="sm"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                Delete
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-proxima-regular text-black">
                Are you sure you want to remove these details?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* View careers */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="sm"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5">
              {selectedRow && (
                <div>
                  <p className="text-base font-proxima-semibold text-black mb-4">
                    <strong className="font-proxima-regular">Name:</strong>{" "}
                    {selectedRow.firstName} {selectedRow.lastName}
                  </p>
                  <p className="text-base font-proxima-semibold text-black mb-4">
                    <strong className="font-proxima-regular">Email:</strong>{" "}
                    {selectedRow.email}
                  </p>
                  <p className="text-base font-proxima-semibold text-black mb-4">
                    <strong className="font-proxima-regular">
                      Phone Number:
                    </strong>{" "}
                    {selectedRow.phoneNumber}
                  </p>
                  <p className="text-base font-proxima-semibold text-black mb-4">
                    <strong className="font-proxima-regular">City:</strong>{" "}
                    {selectedRow.city}
                  </p>
                  <p className="text-base font-proxima-semibold text-black mb-4">
                    <strong className="font-proxima-regular">Message:</strong>{" "}
                    {selectedRow.message}
                  </p>
                  {selectedRow.attachedFile && (
                    <p className="text-base font-proxima-semibold  text-black mb-4">
                      <strong className="font-proxima-regular">Resume:</strong>{" "}
                      <Link
                        to={IMAGE_URL + selectedRow.attachedFile}
                        target="_blank"
                      >
                        <span className="hover:underline cursor-pointer text-blue-700">
                          Click here to view the resume
                        </span>
                      </Link>
                    </p>
                  )}

                  <p className="text-base font-proxima-semibold text-black">
                    <strong className="font-proxima-regular">
                      Date & Time:
                    </strong>{" "}
                    {moment(selectedRow.createdAt).format(
                      "MMM DD, YYYY hh:mm a "
                    )}
                  </p>
                </div>
              )}
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default CareerLeads;
