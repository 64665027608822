import { Button } from "@material-tailwind/react";
import Input from "components/input";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const ViewDetails = () => {
  const userDetails = useSelector((state) => state?.user);
  const userDetailsFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      address: "",
      email: "",
      role: "",
    },
  });

  const changePasswordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    // validationSchema: changePasswordValidation,
    onSubmit: (values) => {
      // Handle Change password logic here
    },
  });

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>View Details | Farmers Insurance</title>
      </Helmet>
      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              User Details
            </h1>
            <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
              View Personal Information and reset your password
            </h2>
          </div>
        </div>

        {/* USER DETAILS FORM */}
        <form onSubmit={userDetailsFormik.handleSubmit}>
          <div className="mt-5">
            {/* DETAILS */}
            <div className="bg-white rounded-lg border border-authBorder">
              <div className="p-5">
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                  {/* NAME */}
                  <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        First Name
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="firstName"
                          id="firstName"
                          // onChange={userDetailsFormik.handleChange}
                          value={userDetails?.user?.firstName}
                          disabled
                          // error={userDetailsFormik.errors.firstName && userDetailsFormik.touched.firstName ? true : false}
                          // placeholder="Write your First Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        Last Name
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="lastName"
                          id="lastName"
                          // onChange={userDetailsFormik.handleChange}
                          value={userDetails?.user?.lastName}
                          disabled
                          // error={userDetailsFormik.errors.lastName && userDetailsFormik.touched.lastName ? true : false}
                          // placeholder="Write your Last Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 ">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        Address
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="address"
                          id="address"
                          // onChange={userDetailsFormik.handleChange}
                          // value={userDetailsFormik.values.address}
                          // error={userDetailsFormik.errors.address && userDetailsFormik.touched.address ? true : false}
                          value={userDetails?.user?.address[0]} //* Yeh array me aarha hai, Iska proper data aaega tab i can test this!
                          disabled
                          // placeholder="Write your address"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        Email Address
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="email"
                          id="email"
                          value={userDetails?.user?.email}
                          disabled
                          // onChange={userDetailsFormik.handleChange}
                          // value={userDetailsFormik.values.email}
                          // error={userDetailsFormik.errors.email && userDetailsFormik.touched.email ? true : false}
                          // placeholder="Write your email address"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        Role
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="role"
                          id="role"
                          // onChange={userDetailsFormik.handleChange}
                          // value={userDetailsFormik.values.role}
                          // error={userDetailsFormik.errors.role && userDetailsFormik.touched.role ? true : false}
                          // placeholder="Write your role"
                          // value={userDetails?.user?.role}
                          value="Admin" //* Hard coded kyunki role me id(number) aarha hai
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        {/* CHANGE PASSWORD */}
        <form onSubmit={changePasswordFormik.handleSubmit}>
          <div className="mt-5">
            {/* DETAILS */}
            <div className="bg-white rounded-lg border border-authBorder">
              <div className="p-5">
                <h1 className="text-2xl font-proxima-bold text-gray-800 tracking-tight">
                  Change Password
                </h1>
                <div className="mt-4 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                  {/* NAME */}
                  <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        First Name
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="oldPassword"
                          id="oldPassword"
                          onChange={changePasswordFormik.handleChange}
                          value={changePasswordFormik.values.oldPassword}
                          error={
                            changePasswordFormik.errors.oldPassword &&
                            changePasswordFormik.touched.oldPassword
                              ? true
                              : false
                          }
                          placeholder="Write your Old Password"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        New Password
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="newPassword"
                          id="newPassword"
                          onChange={changePasswordFormik.handleChange}
                          value={changePasswordFormik.values.newPassword}
                          error={
                            changePasswordFormik.errors.newPassword &&
                            changePasswordFormik.touched.newPassword
                              ? true
                              : false
                          }
                          placeholder="Write your New Password"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button type="submit">Save Password</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ViewDetails;
