/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";
import ReactImageUploading from "react-images-uploading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button } from "@material-tailwind/react";
import { AiFillPlusCircle } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { Editor } from "react-draft-wysiwyg";
import InputField from "../../../components/input";
import { addBlog, getCategoryBlogList } from "../../../service/api";
import { toast } from "react-toast";

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required("Description is required."),
  shortdescription: yup.string().required("Short Description is required."),
  category: yup.object().required("Category is required"),
});

const AddBlog = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [blogLoading, setBlogLoading] = useState(false);
  const [profileImage, setProfileImage] = useState([]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    functionGetBlogCategory();
  }, []);

  const functionGetBlogCategory = () => {
    setBlogLoading(true);
    getCategoryBlogList({ pagination: false })
      .then((res) => {
        if (res.status) {
          let tempArray = res.data.map((element) => ({
            value: element._id,
            label: element.name,
          }));

          setCategoryOptions(tempArray);
        }
      })
      .finally(() => {
        setBlogLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      shortdescription: "",
      category: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("shortdescription", values.shortdescription);
      if (file) formData.append("image", file);
      formData.append("category", values.category.value);

      setLoading(true);
      addBlog(formData)
        .then((res) => {
          if (res.status) {
            toast.success(res.message);
            setFile(null);
            formik.resetForm();
            // Optionally, navigate to the blog list page or update the blog data
          } else {
            toast.error(res.message);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message || "Failed to add blog");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const onImageChange = (imageList) => {
    setProfileImage(imageList);
  };

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="container-full-width">
          <div className="block xl:flex lg:flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
                Add Blog
              </h1>
              <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
                Edit organization member details for website (Change this!)
              </h2>
            </div>
            <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
              <Link to="/blogs">
                <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                  Back to Blogs
                </Button>
              </Link>
              <Button
                type="submit"
                disabled={loading}
                className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out"
              >
                {loading ? "Please wait..." : "Add Blog"}
              </Button>
            </div>
          </div>

          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
              <div className="col-span-2">
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="p-5">
                    <div className="grid grid-cols-1 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                      <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Blog Title <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="name"
                              id="name"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              error={
                                formik.errors.name && formik.touched.name
                                  ? true
                                  : false
                              }
                              placeholder="Write the Blog Title"
                            />
                          </div>
                          {formik.errors.name && formik.touched.name && (
                            <div className="text-red-500 text-xs mt-1">
                              {formik.errors.name}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Category <span className="text-red-500">*</span>
                          </label>
                          <div className="relative mt-1">
                            <Select
                              options={categoryOptions}
                              placeholder="Select Category"
                              className={`${formik.errors.category && "error"}`}
                              classNamePrefix="dropdown"
                              name="category"
                              id="category"
                              value={formik.values.category}
                              onChange={(selectedOption) =>
                                formik.setFieldValue("category", selectedOption)
                              }
                            />

                            {formik.errors.category && formik.touched.category && (
                              <div className="absolute top-3 right-8">
                                <AiOutlineExclamationCircle className="w-5 h-5 text-red-500" />
                              </div>
                            )}

                            {formik.values.category !== "" && (
                              <div className="absolute top-3 right-8">
                                <FaRegCircleCheck className="w-5 h-5 text-green-500" />
                              </div>
                            )}
                          </div>
                          {formik.errors.category &&
                            formik.touched.category && (
                              <div className="text-red-500 text-xs mt-1">
                                {formik.errors.category.label}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Blog Short Description{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="shortdescription"
                              id="shortdescription"
                              value={formik.values.shortdescription}
                              onChange={formik.handleChange}
                              error={
                                formik.errors.shortdescription &&
                                formik.touched.shortdescription
                                  ? true
                                  : false
                              }
                              placeholder="Write the Blog Short Description"
                            />
                          </div>
                          {formik.errors.shortdescription &&
                            formik.touched.shortdescription && (
                              <div className="text-red-500 text-xs mt-1">
                                {formik.errors.shortdescription}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-span-6">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Blog Description{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Editor
                              toolbar={{ options: ["inline", "link"] }}
                              editorClassName="text-editor"
                              wrapperClassName="text-editor-wrapper"
                              placeholder="Write the blog description here"
                              onChange={(content) =>
                                formik.setFieldValue("description", content)
                              }
                            />
                          </div>
                          {formik.errors.description &&
                            formik.touched.description && (
                              <div className="text-red-500 text-xs mt-1">
                                {formik.errors.description}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-1">
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Blog Cover Image
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Attach the profile images for this blog
                    </p>
                  </div>
                  <div className="p-5">
                    <ReactImageUploading
                      multiple
                      value={profileImage}
                      onChange={onImageChange}
                      maxNumber={5}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, onImageRemove }) => (
                        <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                          <div className="col-span-1">
                            <div
                              className="min-w-36 h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                              onClick={onImageUpload}
                            >
                              <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                              <div className="mt-2">
                                <p className="text-xs font-proxima-semibold text-greyText text-center">
                                  Click here to upload images
                                </p>
                                <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                  (JPG, JPEG, PNG)
                                </p>
                              </div>
                            </div>
                          </div>
                          {imageList.map((image, index) => (
                            <div className="col-span-1 relative" key={index}>
                              <img
                                src={image["data_url"]}
                                className="w-full h-productImage object-cover rounded-md"
                                alt="Product"
                              />
                              <div className="absolute top-2 right-2">
                                <Button
                                  className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <FiTrash className="w-3 h-3 text-black" />
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default AddBlog;
