/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { DateRangePicker } from "react-date-range";
import { Helmet } from "react-helmet";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { FaCalendar } from "react-icons/fa6";
import { FiTrash } from "react-icons/fi";
import { IoBan } from "react-icons/io5";

/* USER DEFINED COMPONENTS */
import { Link } from "react-router-dom";
import InputField from "../../../components/input";
import {
  deleteUsers,
  editUsersDetailsById,
  getUsersList,
} from "../../../service/api";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const Members = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [regionsList, setRegionsList] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  /* DATEPICKER VARIABES */
  const [datepicker, setDatepicker] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date().setMonth(new Date().getMonth() - 1)).format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );

  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getRegionsListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getRegionsListFunction();
  };

  const getRegionsListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true,
    };
    if (search) {
      params.search = search;
    }

    getUsersList(params)
      .then((res) => {
        setRegionsList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("Error: ", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteUsers(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getRegionsListFunction();
      });
  };

  /* HANDLE STATUS CHANGE FUNCTION */
  const handleStatusChange = (editId) => {
    editUsersDetailsById(editId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        getRegionsListFunction();
      });
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No.",
      width: "8vh",
      selector: (row, index) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Image",
      width: "8vh",
      selector: (row) => (
        <span>
          <img
            src={
              row?.profileImage
                ? row?.profileImage
                : require("../../../assets/images/organization.png")
            }
            className={
              row?.profileImage
                ? "w-14 h-14 rounded-lg object-cover"
                : "w-10 h-10 rounded-lg "
            }
            alt={row.name}
          />
        </span>
      ),
    },
    {
      name: "Name",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          {row?.firstName} {row?.lastName}
        </span>
      ),
    },
    {
      name: "Customer Email",
      width: "35vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.email}
        </span>
      ),
    },
    {
      name: "Customer Phone",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.phoneNumber}
        </span>
      ),
    },
    {
      name: "Designation",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row?.designation ? row?.designation : "-"}
        </span>
      ),
    },
    {
      name: "Joining Date",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {moment(row?.createdAt).format("DD' MMM YYYY, hh:mm a")}
        </span>
      ),
    },

    {
      name: "Visibility",
      width: "10vh",
      selector: (row) => (
        <span
          className={
            row?.isActive
              ? "text-sm font-proxima-bold text-green-800 text-opacity-60"
              : "text-sm font-proxima-bold text-yellow-700 text-opacity-60"
          }
        >
          {row.isActive ? "Enabled" : "Disabled"}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip
            content="Remove region"
            className="text-sm font-proxima-semibold"
          >
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiTrash className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip>
          <Tooltip
            content="Disable on website"
            className="text-sm font-proxima-semibold"
          >
            <Button
              onClick={() => {
                handleStatusChange(row?._id);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <IoBan className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getRegionsListFunction();
  }, [page, sizePerPage, search]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Members | Farmers Insurance</title>
      </Helmet>

      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-center justify-between">
          {/* HEADING */}
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Organization Members
            </h1>
            <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
              Manage the Members from your organization
            </h2>
          </div>

          {/* SEARCH AND FILTERS */}
          <div className="block xl:flex lg:flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            {/* SEARCHBAR */}
            <div className="w-full xl:w-searchBar lg:w-searchBar">
              <InputField
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
              />
            </div>

            {/* RANGEPICKER */}
            <div className="relative mt-2 xl:mt-0 lg:mt-0 w-full xl:w-auto lg:w-auto">
              <div
                ref={domNode}
                className="w-full xl:w-rangePicker lg:w-rangePicker flex items-center gap-3 border border-authBorder rounded-lg py-3 px-5 bg-white cursor-pointer"
              >
                <FaCalendar className="w-4 h-4 text-greyText" />
                <input
                  type="text"
                  name="dateRange"
                  placeholder="Select the date range"
                  value={`${startDate} - ${endDate}`}
                  onClick={() => setDatepicker(!datepicker)}
                  className="w-full bg-white outline-none font-proxima-semibold text-sm focus:outline-none active:outline-none placeholder:font-proxima-regular transition-all duration-300 ease-linear text-black"
                />
              </div>
              {datepicker && (
                <div className="absolute top-12 right-0 transition-all duration-300 ease-linear z-50">
                  <DateRangePicker
                    ranges={[selectionRange]}
                    dragSelectionEnabled
                    showPreview
                    onChange={(e) => {
                      setStartDate(
                        moment(e.selection.startDate).format("DD/MM/YYYY")
                      );
                      setEndDate(
                        moment(e.selection.endDate).format("DD/MM/YYYY")
                      );
                    }}
                    className="shadow-xl font-proxima-regular rounded-xl border border-authBorder cursor-pointer"
                  />
                </div>
              )}
            </div>

            {/* ADD PRODUCT */}
            <Link to="/members/add">
              <Button className="w-full xl:w-auto lg:w-auto mt-2 xl:mt-0 lg:mt-0 bg-logoYellow rounded-lg py-3 px-8 text-sm font-proxima-semibold shadow-none hover:shadow-none normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                Add Member
              </Button>
            </Link>
          </div>
        </div>

        {/* ORDERS TABLE */}
        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? regionsList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>
      </div>
    </section>
  );
};

export default Members;
