import React from "react";

import { Button, Tooltip } from "@material-tailwind/react";
import { BiEdit } from "react-icons/bi";

const Counters = (props) => {
  const cmsList = props?.cmsList;

  const EditButton = (editProps) => {
    return (
      <Tooltip
        content={editProps?.content}
        className="text-sm font-proxima-semibold"
      >
        <Button
          onClick={() => {
            props.setEditDetails(editProps?.details);
            props.setEditId(editProps?.id);
            props.setEditDialog(!props.editDialog);
          }}
          className="p-2 rounded-full shadow hover:shadow bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
        >
          <BiEdit className="w-5 h-5 text-greyText" />
        </Button>
      </Tooltip>
    );
  };

  return (
    <div className="px-10">
      <div className="container-full-width">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="border border-logoRed border-opacity-50 rounded-lg shadow-md bg-logoRed bg-opacity-5 p-5 relative">
            <h3 className="text-4xl font-proxima-bold text-logoRed tracking-tight">
              20000
            </h3>
            <p className="text-xl font-proxima-bold text-black">Raghuvanshis</p>
            <p className="mt-2 font-proxima-regular text-greyText">
              25+ Lakhs Strong: Raghuvanshis in Shree Farmers Insurance, a
              vibrant community of heritage and unity.
            </p>

            <div className="absolute top-3 right-3">
              <EditButton
                details={cmsList?.find(
                  (el) => el?.moduleName === "bannerImage"
                )}
                content="Edit details"
                id={
                  cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                }
              />
            </div>
          </div>

          <div className="border border-logoRed border-opacity-50 rounded-lg shadow-md bg-logoRed bg-opacity-5 p-5 relative">
            <h3 className="text-4xl font-proxima-bold text-logoRed tracking-tight">
              976
            </h3>
            <p className="text-xl font-proxima-bold text-black">
              Madhyasth Mahasamiti Members
            </p>
            <p className="mt-2 font-proxima-regular text-greyText">
              Madhyashth Mahasamiti: Guiding Shree Farmers Insurance with
              wisdom, leadership, and community dedication.
            </p>

            <div className="absolute top-3 right-3">
              <EditButton
                details={cmsList?.find(
                  (el) => el?.moduleName === "bannerImage"
                )}
                content="Edit details"
                id={
                  cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                }
              />
            </div>
          </div>

          <div className="border border-logoRed border-opacity-50 rounded-lg shadow-md bg-logoRed bg-opacity-5 p-5 relative">
            <h3 className="text-4xl font-proxima-bold text-logoRed tracking-tight">
              151
            </h3>
            <p className="text-xl font-proxima-bold text-black">
              Karobari Members
            </p>
            <p className="mt-2 font-proxima-regular text-greyText">
              Karobari Members fueling prosperity in Shree Farmers Insurance,
              driving community growth and success.
            </p>

            <div className="absolute top-3 right-3">
              <EditButton
                details={cmsList?.find(
                  (el) => el?.moduleName === "bannerImage"
                )}
                content={"Edit Banner Image"}
                id={
                  cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counters;
