import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* USER DEFINED COMPONENTS */
import LoadingButton from "components/button";
import Input from "components/input";

/* ICONS */
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* REDUX IMPORTS */
import { useDispatch } from "react-redux";
import actions from "redux/actions/userAction";

import { useFormik } from "formik";
import * as yup from "yup";

/* API IMPORT */
import { adminLogin, getUsersDetailsById } from "service/api";

/* VALIDATION SCHEMA */
const loginValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Email address is required"),
  password: yup
    .string()
    .min(2, "Too short")
    .required("Password is required"),
});

const AuthLogin = () => {
  // GLOBAL STATES
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // STATE VARIABLES
  const [loginPassword, setLoginPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  // FORMIK REGISTRATION FUNCTION
  const loginHandler = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidation,
    onSubmit: (values) => {
      setLoader(true);
      adminLogin(values)
        .then(async (res) => {
          if (res?.status) {
            dispatch(actions.setToken(res?.data?.token));
            dispatch(actions.setLoggedIn(true));
            await getUserDetails(res?.data?.userData);

            toast.success(res?.message);
            loginHandler.resetForm();
            navigate("/contact-us");
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    },
  });

  const getUserDetails = (id) => {
    getUsersDetailsById(id)
      .then((res) => {
        if (res?.status) {
          dispatch(actions.setUser(res?.data));
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <section className="bg-authBg">
      {/* AUTH FORM */}
      <div className="h-authForm">
        <div className="container h-full flex items-center justify-center">
          <div className="w-[60vh] bg-white rounded-lg border border-authBorder p-10">
            <div className="mt-0">
              <h1 className="text-xl font-proxima-bold text-black">
                Admin Panel Login
              </h1>
              <h2 className="text-base font-proxima-regular text-greyText">
                You can access the details submitted by people from your website
                on this dashboard.
              </h2>
            </div>

            <form onSubmit={loginHandler.handleSubmit}>
              {/* EMAIL ADDRESS */}
              <div className="form-group mt-5">
                <label className="text-base font-proxima-regular text-black">
                  Email address <span className="text-red-500">*</span>
                  <div className="mt-1">
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      value={loginHandler.values.email}
                      onChange={loginHandler.handleChange}
                      error={
                        loginHandler.errors.email && loginHandler.touched.email
                      }
                    />
                    {/* {loginHandler.errors.email && loginHandler.touched.email && (
                      <p className="text-red-500">{loginHandler.errors.email}</p>
                    )} */}
                  </div>
                </label>
              </div>

              {/* PASSWORD */}
              <div className="form-group mt-5">
                <label className="text-base font-proxima-regular text-black">
                  Password <span className="text-red-500">*</span>
                  <div className="mt-1 relative">
                    <Input
                      type={loginPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={loginHandler.values.password}
                      onChange={loginHandler.handleChange}
                      error={
                        loginHandler.errors.password &&
                        loginHandler.touched.password
                      }
                    />
                    {/* {loginHandler.errors.password &&
                      loginHandler.touched.password && (
                        <p className="text-red-500">{loginHandler.errors.password}</p>
                    )} */}
                    <Button
                      className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-2 right-2"
                      onClick={() => setLoginPassword(!loginPassword)}
                    >
                      {loginPassword ? (
                        <IoMdEyeOff className="w-5 h-5 text-greyText" />
                      ) : (
                        <IoMdEye className="w-5 h-5 text-greyText" />
                      )}
                    </Button>
                  </div>
                </label>
              </div>

              <LoadingButton type="submit" label="Login" loading={loader} />
            </form>
            {/* <p className="text-base font-proxima-semibold text-black mt-3 text-center">
              New on our platform?{" "}
              <a className=" text-[#003087]" href="/register">
                Create an account
              </a>
            </p> */}
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <div className="py-3 border-t border-authBorder bg-white">
        <div className="container">
          <div className="flex items-center justify-between">
            <p className="text-base font-proxima-regular text-black">
              Copyright 2024 © Farmers Insurance. All rights reserved.
            </p>
            <p className="text-base font-proxima-regular text-black">
              Designed by{" "}
              <a
                href="https://www.roundtechsquare.com"
                target="_blank"
                rel="noreferrer"
                className="text-sm font-proxima-semibold text-blue-500"
              >
                RoundTechSquare
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthLogin;
