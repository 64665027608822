import React from "react";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Tooltip } from "@material-tailwind/react";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";

const Hero = (props) => {
  const cmsList = props?.cmsList;

  const EditButton = (editProps) => {
    return (
      <Tooltip
        content={editProps?.content}
        className="text-sm font-proxima-semibold"
      >
        <Button
          onClick={() => {
            props.setEditDetails(editProps?.details);
            props.setEditId(editProps?.id);
            props.setEditDialog(!props.editDialog);
          }}
          className="p-2 rounded-full shadow hover:shadow bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
        >
          <BiEdit className="w-5 h-5 text-greyText" />
        </Button>
      </Tooltip>
    );
  };

  return (
    <div className="py-0 bg-banner">
      <div className="w-full h-full bg-black bg-opacity-80 flex items-center rounded-2xl">
        <div className="container">
          <div className="float-end">
            <EditButton
              details={cmsList?.find((el) => el?.moduleName === "bannerImage")}
              content={"Edit Banner Image"}
              id={cmsList?.find((el) => el?.moduleName === "bannerImage")?._id}
            />
          </div>

          <EditButton
            details={cmsList?.find(
              (el) => el?.moduleName === "bannerHeadingText"
            )}
            content={"Edit Banner Header Text"}
            id={
              cmsList?.find((el) => el?.moduleName === "bannerHeadingText")?._id
            }
          />

          <div className="w-full xl:w-3/5 lg:w-3/5 h-full">
            <h1 className="text-4xl xl:text-6xl lg:text-6xl font-sunflower-bold text-white leading-tight">
              {
                cmsList?.find((el) => el?.moduleName === "bannerHeadingText")
                  ?.description
              }
            </h1>

            <EditButton
              details={cmsList?.find(
                (el) => el?.moduleName === "bannerParagraphText"
              )}
              content={"Edit Banner Header Description"}
              id={
                cmsList?.find((el) => el?.moduleName === "bannerParagraphText")
                  ?._id
              }
            />

            <h2 className="text-xl xl:text-2xl lg:text-2xl font-proxima-regular text-white text-opacity-65 leading-relaxed">
              {
                cmsList?.find((el) => el?.moduleName === "bannerParagraphText")
                  ?.description
              }
            </h2>
            <EditButton
              details={cmsList?.find(
                (el) => el?.moduleName === "bannerRedirectButton"
              )}
              content={"Edit Banner Button"}
              id={
                cmsList?.find((el) => el?.moduleName === "bannerRedirectButton")
                  ?._id
              }
            />
            <Link
              target="_blank"
              to={
                cmsList?.find((el) => el?.moduleName === "bannerRedirectButton")
                  ?.redirectURL
              }
            >
              <Button className="mt-5 xl:mt-10 lg:mt-10 shadow-none hover:shadow-none bg-white hover:bg-opacity-80 rounded-md text-black normal-case text-base xl:text-lg lg:text-lg font-proxima-semibold transition-all duration-300 ease-in-out">
                {
                  cmsList?.find(
                    (el) => el?.moduleName === "bannerRedirectButton"
                  )?.description
                }
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
