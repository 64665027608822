import {
  Card,
  CardBody,
  CardHeader,
  Chip,
  Typography,
} from "@material-tailwind/react";

import { Button } from "@material-tailwind/react";
import { IMAGE_URL } from "config";
import { useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";

const BlogCard = ({ blog }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <Card className="w-full max-w-[22rem] shadow-lg">
      <CardHeader floated={false} color="blue-gray">
        <img
          src={IMAGE_URL + blog.image}
          alt="blog cover"
          className="h-40 w-full"
        />
        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
        <div className="!absolute top-4 right-4">
          <Button
            onClick={toggleDropdown}
            data-ripple-light="true"
            data-popover-target="menu"
            className="p-2 bg-white rounded-full border border-gray-300 hover:border-black-500 transition-all duration-300 ease-in-out"
          >
            <HiOutlineDotsVertical className="w-4 h-4 text-black" />
          </Button>
          {isDropdownOpen && (
            <ul
              role="menu"
              data-popover="menu"
              data-popover-placement="bottom"
              className="absolute z-10 right-0 mt-2 w-25 overflow-auto rounded-md border border-blue-gray-50 bg-white p-3 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none"
            >
              <li
                role="menuitem"
                className="block cursor-pointer select-none rounded-md px-3 py-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
              >
                Edit
              </li>
              <li
                role="menuitem"
                className="block text-red-500 cursor-pointer select-none rounded-md px-3 py-2 text-start leading-tight transition-all hover:bg-red-50 hover:bg-opacity-80 hover:text-red-500 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
              >
                Delete
              </li>
            </ul>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <div className="mb-3 flex flex-col gap-2">
          <div className="inline-flex mb-2">
            {/* Check if blog has a category array and get the name of the first category */}
            {blog.category && blog.category.length > 0 && (
              <Chip
                variant="ghost"
                value={blog.category[0].name}
                className="rounded-full"
              />
            )}
          </div>
          <Typography
            variant="h5"
            color="blue-gray"
            className="font-proxima-semibold"
          >
            {blog.title}
          </Typography>
          <Typography variant="body2" color="gray" className="mt-1">
            {blog.description}
          </Typography>
        </div>
      </CardBody>
    </Card>
  );
};

export default BlogCard;
